import {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import InView from 'react-intersection-observer';
import {EDetailButtonType, TSearchPoi} from 'types/App';
import {parsePoiInfoToNavInfo} from 'utils/search';
import {getAppActionButton} from 'utils/tmapUtils';
import {AccommodationReservationButton} from 'components/AccommodationReservationButton';
import {PoiListItem} from 'components/PoiListItem';
import SearchNaverAd from 'components/search/SearchNaverAd';
import SearchResultSubPoiItem from 'components/search/SearchResultSubPoiItem';
import {generateTagComponentList} from 'hooks/usePoiMainContentTag';
import {useFocusDrawerList} from 'hooks/useFocusDrawerList';
import {useSearchImageList} from 'hooks/useSearchImageList';
import useMoveToTarget from 'hooks/useMoveToTarget';
import useLogger from 'hooks/useLogger';
import useAddress from 'hooks/useAddress';
import {
  DSP_AD_POSITION_IDX,
  EAdCode,
  ETMapBannerCode,
  isBannerTestEnv,
  NAVER_SA_POSITION_IDX,
  SEARCH_BANNER_AD_STEP,
  SEARCH_RESULT_BANNER_PROVIDER_CONFIG,
  TEST_BANNER_PROVIDER_CONFIG,
} from 'constant/Ads';
import CatchTableReservationButton from 'components/CatchTableReservationButton';
import {ReloadResume} from 'components/ReloadResume';
import {AdBanner} from 'components/AdBanner';
import {useAppSelector} from 'ducks/hooks';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import {IcArrowDownBold} from 'components/@tmds/icons/v1.2/IcArrowDownBold';
import {useAdRequestParams} from 'hooks/useAdRequestParams';
import {getLocalAdLogs, useTMapAds} from 'hooks/useTMapAds';
import SearchPoiAdItem from './SearchPoiAdItem';
import {TPoiData} from './SearchRecommendPoiItem';
import classNames from 'classnames';
import {useAdBanner} from 'context/AdBannerContext';

import s from 'styles/components/search/SearchResultPoiList.module.scss';

type TProps = {
  list: TSearchPoi[];
  searchQuery: string;
};

const SearchResultPoiList = ({list, searchQuery}: TProps) => {
  const {activePoi, changeViewingItems} = useFocusDrawerList(list);
  const imageCounter = useSearchImageList();
  const {sendSearchResultItemClickLog, sendCouponClickLog, sendReservationClickLog} = useLogger();
  const {selectAddress} = useAddress();
  const {moveToDetail, moveToSelectDestinationAction, reqMode, reqType, extra} = useMoveToTarget();
  const {isLogInitialized, userInteraction} = useAppSelector((state) => ({
    isLogInitialized: state.log.isInitialize,
    userInteraction: state.userInteraction,
  }));
  const {queries} = useParseQueryLocation();
  const hasPoiAd = useMemo(
    () => list.find((v) => v.special?.advertiseInfo?.isPoiAdvertiseYn),
    [list]
  );
  const [groupSubFoldInfo, setGroupSubFoldInfo] = useState<{[key: number]: boolean}>({});

  const {isAdTop} = useAdBanner();
  const {requestSetting} = useAdRequestParams({
    inventoryCode: ETMapBannerCode.SEARCH_KEYWORD,
  });
  const {callCompleteLog} = useTMapAds(requestSetting);

  const saPosition = useMemo(() => {
    if (list.length < 2) {
      return -1;
    }
    if (list.length === 2) {
      return NAVER_SA_POSITION_IDX - 1;
    }

    return NAVER_SA_POSITION_IDX;
  }, [list]);

  const dspPosition = useMemo(() => {
    if (list.length <= DSP_AD_POSITION_IDX) {
      return -1;
    }

    // https://tmobi.atlassian.net/browse/SEARCH-234
    // 2번째 DSP 배너 노출시, POI AD는 list 결과 순서에 포함되지 않음. total api 응답값에서는 포함돼서 내려옴
    return DSP_AD_POSITION_IDX;
  }, [list]);

  const parseNavInfo = useCallback(
    (v: TSearchPoi) => {
      return parsePoiInfoToNavInfo({
        pkey: v.pkey || '',
        poiId: v.poiId || '',
        navSeq: `${v.navSeq || ''}`,
        navX: `${v.navX || ''}`,
        navY: `${v.navY || ''}`,
        centerX: `${v.centerX || ''}`,
        centerY: `${v.centerY || ''}`,
        rpFlag: v.rpFlag,
        poiName: v.listName || v.poiName,
        address: selectAddress({jibun: v.fullJibunAddr, road: v.fullRoadAddr}),
        tel: v.displayPhoneNumber,
        ...(v.stationInternalId && {
          stationId: v.stationInternalId,
          publicTransportType: v.stationType,
        }),
      });
    },
    [selectAddress]
  );

  const handleClickItem = useCallback(
    (e, v, index, tags) => {
      e.preventDefault();
      e.stopPropagation();

      sendSearchResultItemClickLog('list_tap.poi_detail', {
        index,
        tags,
      });
      moveToDetail(parseNavInfo(v));
    },
    [sendSearchResultItemClickLog, moveToDetail, parseNavInfo]
  );

  const handleClickRouteButton = useCallback(
    (e, v, index, tags, actionButtonType) => {
      e.preventDefault();
      e.stopPropagation();

      const actionId =
        actionButtonType === EDetailButtonType.CONFIRM
          ? 'list_tap.select_poi'
          : 'list_tap.poi_direction';

      sendSearchResultItemClickLog(actionId, {
        index,
        tags,
      });
      moveToSelectDestinationAction(parseNavInfo(v));
    },
    [sendSearchResultItemClickLog, moveToSelectDestinationAction, parseNavInfo]
  );

  const handleClickPoiAd = useCallback(
    (v, clickTo) => {
      const {click} = getLocalAdLogs(v.special?.advertiseInfo);

      click && callCompleteLog(click, {clickTo});
    },
    [callCompleteLog]
  );

  const handlePoiAdShow = useCallback(
    (v) => {
      const {vimp} = getLocalAdLogs(v.special?.advertiseInfo);

      vimp && callCompleteLog(vimp);
    },
    [callCompleteLog]
  );

  const renderAdBanner = useCallback(
    (adcode) => {
      return (
        <div className={s.banner_wrap}>
          <ReloadResume>
            <AdBanner
              isLogInitialized={isLogInitialized}
              visibleLandscape={true}
              adCode={adcode}
              adTypeStep={SEARCH_BANNER_AD_STEP}
              adTypeOption={{
                ...SEARCH_RESULT_BANNER_PROVIDER_CONFIG,
                ...(isBannerTestEnv ? TEST_BANNER_PROVIDER_CONFIG : {}),
              }}
              logData={{
                custom: {search_query: queries.searchQuery},
                includeTicketId: true,
              }}
            />
          </ReloadResume>
        </div>
      );
    },
    [isLogInitialized, queries.searchQuery]
  );

  useEffect(() => {
    const activeId = userInteraction.activePoi;

    if (document.querySelector<HTMLDivElement>(`[data-id="${activeId}"]`)) {
      return;
    }

    // https://tmobi.slack.com/archives/C04PS6R28TX/p1731567172121559?thread_ts=1731566868.059089&cid=C04PS6R28TX
    for (const [groupIndex, item] of list.entries()) {
      const hasGroupPosition = item.groupSubList.some((sub) => sub.listId === activeId);

      if (hasGroupPosition) {
        setGroupSubFoldInfo((prev) => ({
          ...prev,
          [groupIndex]: true,
        }));
        return;
      }
    }
  }, [userInteraction.activePoi]);

  return (
    <>
      {list.map((v, idx) => {
        const tags = generateTagComponentList(v);
        const actionButtonType = getAppActionButton({reqMode, reqType, extra});
        const needFold = v.groupSubList.length >= 2;
        const needGroupSubFold = !groupSubFoldInfo[idx] && needFold;
        const firstSub = v.groupSubList[0];
        const isLocalAd = v.special?.advertiseInfo?.isPoiAdvertiseYn;

        return (
          <Fragment key={idx}>
            {!hasPoiAd && isAdTop && idx === 0 && renderAdBanner(EAdCode.SEARCH_RESULT_CS)}
            <InView
              as="li"
              className={classNames(s.item, {[s.is_ad]: isLocalAd})}
              key={v.listId}
              data-type="poi"
              data-id={v.listId}
              threshold={0.5}
              onChange={(e) => changeViewingItems(e, idx)}
              data-focus={activePoi === v.listId}
            >
              {isLocalAd ? (
                <SearchPoiAdItem
                  poiData={v as unknown as TPoiData}
                  onClickItem={(e) => {
                    handleClickItem(e, v, idx, tags);
                    handleClickPoiAd(v, 'place');
                  }}
                  onClickRouteButton={(e) => {
                    handleClickRouteButton(e, v, idx, tags, actionButtonType);
                    handleClickPoiAd(v, 'drive');
                  }}
                  onShow={() => handlePoiAdShow(v)}
                />
              ) : (
                <PoiListItem
                  idx={idx}
                  actionButtonType={actionButtonType}
                  focus={activePoi === v.listId}
                  poiData={{
                    ...v,
                    imageInfo: imageCounter.getListByViewport(v.imageInfo),
                  }}
                  onClickItem={(e) => {
                    handleClickItem(e, v, idx, tags);
                  }}
                  onClickRouteButton={(e) => {
                    handleClickRouteButton(e, v, idx, tags, actionButtonType);
                  }}
                />
              )}

              <CatchTableReservationButton
                poiData={v}
                onClickReservation={() => {
                  sendReservationClickLog('tap.reserve', {index: idx, tags});
                }}
                onClickWaiting={() => {
                  sendReservationClickLog('tap.waiting', {index: idx, tags});
                }}
              />
              <AccommodationReservationButton
                poiData={v}
                onClick={() => {
                  sendSearchResultItemClickLog('list_tap.reservation_accommodation', {
                    index: idx,
                    tags,
                  });
                }}
              />
            </InView>
            <>
              {needFold && (
                <div
                  className={s.sub_more}
                  onClick={() => {
                    if (groupSubFoldInfo[idx]) {
                      return;
                    }

                    sendSearchResultItemClickLog('list_tap.poigrp_open', {
                      index: idx,
                      tags,
                    });
                    setGroupSubFoldInfo({
                      ...groupSubFoldInfo,
                      [idx]: true,
                    });
                  }}
                >
                  <span className={s.text}>진입장소</span>
                  <span className={s.count}>{v.groupSubList.length}</span>
                  {needGroupSubFold && (
                    <span>
                      <IcArrowDownBold width={12} height={12} color="gray400" />
                    </span>
                  )}
                </div>
              )}

              {needGroupSubFold ? (
                <InView
                  as="li"
                  className={s.sub_item}
                  key={firstSub.listId}
                  data-type="poi"
                  data-id={firstSub.listId}
                  threshold={0.5}
                  onChange={(e) => changeViewingItems(e, idx, 0)}
                  data-focus={activePoi === firstSub.listId}
                >
                  <SearchResultSubPoiItem
                    actionButtonType={actionButtonType}
                    idx={0}
                    parentPoiData={v}
                    /* TODO fix */
                    poiData={firstSub as any}
                    onClick={() => {
                      sendSearchResultItemClickLog('list_tap.poigrp_detail', {
                        index: 0,
                        tags,
                      });
                    }}
                    onClickRouteButton={() => {
                      sendSearchResultItemClickLog('list_tap.poigrp_direction', {
                        index: 0,
                        tags,
                      });
                    }}
                    onClickCoupon={() => {
                      sendCouponClickLog({idx: 0, tags});
                    }}
                  />
                </InView>
              ) : (
                v.groupSubList.map((sub, subIdx) => {
                  return (
                    <InView
                      as="li"
                      className={s.sub_item}
                      key={sub.listId}
                      data-type="poi"
                      data-id={sub.listId}
                      threshold={0.5}
                      onChange={(e) => changeViewingItems(e, idx, subIdx)}
                      data-focus={activePoi === sub.listId}
                    >
                      <SearchResultSubPoiItem
                        actionButtonType={actionButtonType}
                        idx={subIdx}
                        parentPoiData={v}
                        /* TODO fix */
                        poiData={sub as any}
                        onClick={() => {
                          sendSearchResultItemClickLog('list_tap.poigrp_detail', {
                            index: subIdx,
                            tags,
                          });
                        }}
                        onClickRouteButton={() => {
                          sendSearchResultItemClickLog('list_tap.poigrp_direction', {
                            index: subIdx,
                            tags,
                          });
                        }}
                        onClickCoupon={() => {
                          sendCouponClickLog({idx, tags});
                        }}
                      />
                    </InView>
                  );
                })
              )}
            </>

            {!hasPoiAd && !isAdTop && idx === 0 && renderAdBanner(EAdCode.SEARCH_RESULT)}
            {idx === (hasPoiAd ? saPosition + 1 : saPosition) && (
              <SearchNaverAd className={s.keyword_banner_wrap} searchQuery={searchQuery} />
            )}
            {hasPoiAd && idx === dspPosition && renderAdBanner(EAdCode.SEARCH_RESULT_LAST)}
          </Fragment>
        );
      })}
    </>
  );
};

export default SearchResultPoiList;
