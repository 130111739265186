import {TPlaceSettings} from './type';

const saveMapLevelClustring = {
  cluster: [3, 3, 3, 3, 3, 3, 3, 3, 3, 5, 5, 5],
  minFavCount: 1000,
};

const tplaceSettings: TPlaceSettings = {
  aroundContentList: [
    {
      type: 'AD_BANNER_01',
      order: 0,
    },
    {
      type: 'RANKING',
      order: 10,
    },
    {
      type: 'AD_BANNER_02',
      order: 19,
    },
  ],
  discoveryContentList: [
    {
      type: 'TRIP',
    },
    {
      type: 'SIMILAR',
    },
    {
      type: 'WEEKEND',
    },
    {
      type: 'RANKING',
    },
    {
      type: 'DELICIOUS',
    },
  ],
  curationContentList: [],
};

export const remoteDefaultData = {
  saveMapLevelClustring,
  tplaceSettings,
};
