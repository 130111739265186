import {CSSProperties, useMemo, useState} from 'react';
import classNames from 'classnames';
import LocalStorage from '@lcc/web-storage';

import packageInfo from '../../package.json';

import {EBuildInfoTabs, EDataType} from 'types/BuildInfo';

import {APP_SERVER_NAME, isProdEnv, isLocalOrDev} from 'constant/Env';
import {Paths} from 'constant/RoutePath';
// import {ESearchWebHost} from 'constant/Path';
import {StorageKey} from 'constant/Storage';

import Tabs, {TabContent, TabHeader} from 'modules/Tabs';

import {useAppSelector} from 'ducks/hooks';
import {usePerformanceLog} from 'hooks/usePerformanceLog';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';

import ua from 'utils/uaParser';

import BuildInfoListItem from 'components/BuildInfoItem';

import s from 'styles/components/BuildInfo.module.scss';
import {BuildInfoOriginInput} from './BuildInfoOriginInput';
import {BuildInfoStackViewToggle} from './BuildInfoStackViewToggle';
import {NEW_HOME_KEY} from 'utils/dev';
import HybridBridge from 'utils/searchBar';
import {useSearchMainTab} from 'hooks/useSearchMainTab';
import {ESearchTabs} from 'types/Search';
import {generateUrl, moveToUrl} from 'utils/url';
import {DefaultDeepLinkReqKeyMap} from 'constant/DeepLink';
import {sendWithScheme} from '@lcc/tmap-inapp';

const tabList = [
  EBuildInfoTabs.BASE_INFO,
  EBuildInfoTabs.ADDITIONAL_INFO,
  EBuildInfoTabs.DEV,
  EBuildInfoTabs.LINK,
];

const tabHeaderList = tabList.map((n) => ({key: n, title: n}));
const BUILD_INFO_BOTTOM_STORAGE_KEY = 'BUILD_INFO_BOTTOM';

type TProps = {
  isSearch?: boolean;
};

const BuildInfo = ({isSearch}: TProps) => {
  const {reduxState, rdLayout, rdUserInfo, rawRemoteConfig} = useAppSelector((state) => ({
    reduxState: state,
    rdLayout: state.layout,
    rdUserInfo: state.userInfo,
    rawRemoteConfig: state.remote.rawRemoteData,
  }));

  const [isView, setView] = useState<boolean>(false);
  const [showWebviewCover, setShowWebviewCover] = useState(false);
  const {viewing} = usePerformanceLog();
  const {originQueries, queries, tailParam, search} = useParseQueryLocation();
  const initLocation = useMemo(() => window.location.href.replace(window.location.origin, ''), []);

  const TmapInfoFromApp = (window as any)?.tmapInfo as any;
  const [buildInfoBottom, setBuildInfoBottom] = useState(
    Number(LocalStorage.getItem(BUILD_INFO_BOTTOM_STORAGE_KEY)) || 100
  );

  const {changeTab} = useSearchMainTab();

  const baseInfos = useMemo(() => {
    if (!isView) {
      return [];
    }

    return [
      {
        name: '',
        value: '새로고침',
        type: EDataType.BUTTON,
        handleCLick: () => window.location.reload(),
        ignoreCopy: true,
      },

      {name: 'Package Version - dev', value: packageInfo.version, type: EDataType.VALUE},
      {name: 'init url', value: initLocation, type: EDataType.DANGER_VALUE},
      {
        name: 'url',
        value: window.location.href.replace(window.location.origin, ''),
        type: EDataType.DANGER_VALUE,
      },

      {name: 'accessKey', value: rdUserInfo.accessKey, type: EDataType.VALUE},
      {name: 'userKey', value: rdUserInfo.userKey, type: EDataType.VALUE},
      {name: 'sessionKey', value: rdUserInfo.sessionKey, type: EDataType.VALUE},
      {name: 'deviceId', value: rdUserInfo.device?.deviceId, type: EDataType.VALUE},
      {name: 'sessionId', value: rdUserInfo.sessionId, type: EDataType.VALUE},
      {name: 'adid / idfa', value: rdUserInfo.adId, type: EDataType.VALUE},
      {
        name: 'deviceServiceVendorId',
        value: rdUserInfo.deviceServiceVendorId,
        type: EDataType.VALUE,
      },

      {name: 'Server Environment', value: APP_SERVER_NAME, type: EDataType.VALUE},
    ];
  }, [isView, rdUserInfo]);

  const additionalInfos = useMemo(() => {
    if (!isView) {
      return [];
    }

    const cookieInfo: any[] = [];
    document.cookie.split(';').forEach((n) => {
      if (n) {
        const [name, ...values] = n.split('=');
        cookieInfo.push({name, value: values.join('=')});
      }
    });

    const storageInfo: any[] = [];
    if (window.localStorage) {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        if (key) {
          const value = LocalStorage.getItem(key);

          storageInfo.push({
            name: key,
            value: StorageKey[key]
              ? Array.isArray(value)
                ? value.map((n) => JSON.stringify(n))
                : value
              : JSON.stringify(value),
          });
        }
      }
    }

    const paramInfos: any[] = [];
    for (let key in originQueries) {
      paramInfos.push({name: `originQueries.${key}`, value: originQueries[key]});
    }
    for (let key in queries) {
      paramInfos.push({name: `queries.${key}`, value: queries[key]});
    }
    for (let key in tailParam) {
      paramInfos.push({name: `tailParam.${key}`, value: tailParam[key]});
    }
    paramInfos.push({name: 'search', value: search});

    const ausmUserInfo: any[] = [];
    for (let key in window['asumUserInfo'] || {}) {
      ausmUserInfo.push({name: key, value: JSON.stringify(window['asumUserInfo'][key])});
    }

    const vsmInfo: any[] = [
      {
        name: 'config uri',
        value: (window as any).___map?.getConfig()?.uri,
      },
    ];

    return [
      {name: 'App Info', value: ua, type: EDataType.OBJECT, expandedPaths: ['root', 'root.*']},
      {name: 'Params', value: paramInfos, type: EDataType.KEY_VALUE},
      {name: 'cookies', value: cookieInfo, type: EDataType.KEY_VALUE},
      {name: 'LocalStorage', value: storageInfo, type: EDataType.KEY_VALUE},

      {
        name: 'AsumUserInfo',
        value: ausmUserInfo,
        type: EDataType.OBJECT,
      },

      {name: 'VSM Info', value: vsmInfo, type: EDataType.KEY_VALUE, ignoreCopy: true},

      {
        name: 'Firebase Config',
        value: rawRemoteConfig,
        type: EDataType.OBJECT,
      },
    ];
  }, [isView, originQueries, queries, search, tailParam, rawRemoteConfig]);

  // const devInfos = useMemo(() => {
  //   if (!isView) {
  //     return [];
  //   }

  //   const devLinks: any[] = ((targetPath) => [
  //     {
  //       name: 'Dev',
  //       pushLink: `${ESearchWebHost.DEV_DOMAIN}${targetPath}`,
  //     },
  //     {
  //       name: 'Stage',
  //       pushLink: `${ESearchWebHost.STAGE_DOMAIN}${targetPath}`,
  //     },
  //     {
  //       name: 'Rtg',
  //       pushLink: `${ESearchWebHost.RTG_DOMAIN}${targetPath}`,
  //     },
  //     {
  //       name: 'Prod',
  //       pushLink: `${ESearchWebHost.PROD_DOMAIN}${targetPath}`,
  //     },
  //     {
  //       name: 'Rachel Local',
  //       pushLink: `http://192.168.45.78:3000${targetPath}`,
  //     },
  //     {
  //       name: 'Rachel Office',
  //       pushLink: `http://150.50.75.218:3000${targetPath}`,
  //     },
  //   ])(`${window.location.pathname}${window.location.search}`);

  //   return [{name: 'DEV Links', value: devLinks, type: EDataType.KEY_VALUE}];
  // }, [isView]);

  const testInfos = useMemo(() => {
    if (!isView) {
      return [];
    }
    const links: any[] = [
      {
        name: 'VSM 렌더링',
        pushLink: `${Paths.DevVSMTest}`,
      },
      {
        name: 'RedDot',
        pushLink: `${Paths.DevRedDot}`,
      },
      {
        name: '하이브리드',
        pushLink: `${Paths.DevHybridBridge}`,
      },
      {
        name: '앱 인터페이스',
        pushLink: `${Paths.DevAppInterface}`,
      },
      {
        name: '배너 테스트',
        pushLink: `${Paths.ProtoBanner}`,
      },
      {
        name: '큐레이션 테스트',
        pushLink: Paths.ProtoCuration,
      },
    ];

    return [
      {name: 'Url Test', value: '', type: EDataType.INPUT},
      {name: 'links', value: links, type: EDataType.KEY_VALUE, ignoreCopy: true},
    ];
  }, [isView]);

  if (isProdEnv) {
    return null;
  }

  return (
    <div
      className={classNames(s.build_info_wrap, {
        [s.view_detail]: isView,
      })}
      onClick={() => setView(false)}
      role="presentation"
      aria-hidden="true"
    >
      <div
        className={s.build_info_inner_box}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={
          {
            '--hybrid-top': `${
              rdLayout.isHybrid
                ? rdLayout.appSize.searchBarHeight + rdLayout.appSize.statusBarHeight + 10
                : 30
            }px`,
          } as CSSProperties
        }
      >
        <Tabs values={tabList} initValue={EBuildInfoTabs.BASE_INFO}>
          <div className={s.tab_header}>
            {tabHeaderList.map((n) => (
              <TabHeader
                key={n.key}
                className={s.header_item}
                activeClassName={s.active}
                tabKey={n.key}
              >
                {n.title}
              </TabHeader>
            ))}
          </div>

          <div className={s.info_wrap}>
            <TabContent tabKey={EBuildInfoTabs.BASE_INFO}>
              {baseInfos.map((n, i) => (
                <BuildInfoListItem data={n} key={i} />
              ))}
            </TabContent>

            <TabContent tabKey={EBuildInfoTabs.ADDITIONAL_INFO}>
              {additionalInfos.map((n, i) => (
                <BuildInfoListItem data={n} key={i} />
              ))}
            </TabContent>

            <TabContent tabKey={EBuildInfoTabs.DEV}>
              <BuildInfoListItem
                data={{
                  type: EDataType.CUSTOM,
                  name: 'dev log stack view',
                  value: <BuildInfoStackViewToggle />,
                }}
              />

              <BuildInfoListItem
                data={{
                  type: EDataType.CUSTOM,
                  name: 'move to tnow',
                  value: (
                    <div className={classNames(s.btn_wrap, s.flex_row)}>
                      <button
                        onClick={() =>
                          moveToUrl(
                            generateUrl(window.location.origin + Paths.PlaceMain, {
                              centerLat: reduxState.map.nowCenter?.lat,
                              centerLon: reduxState.map.nowCenter?.lon,
                              tailParam: JSON.stringify({stand_alone_view: 1}),
                            })
                          )
                        }
                      >
                        티지금 랜딩하기
                      </button>
                    </div>
                  ),
                }}
              />

              <BuildInfoListItem
                data={{
                  type: EDataType.CUSTOM,
                  name: 'show webview cover',
                  value: (
                    <div className={classNames(s.btn_wrap, s.flex_row)}>
                      <button onClick={() => setShowWebviewCover((p) => !p)}>
                        웹뷰 커버 {showWebviewCover ? '가리기' : '보이기'}
                      </button>
                    </div>
                  ),
                }}
              />

              <BuildInfoListItem
                data={{
                  type: EDataType.CUSTOM,
                  name: 'click map icon',
                  value: (
                    <div className={classNames(s.btn_wrap, s.flex_row)}>
                      <button
                        onClick={() => {
                          if (rdLayout.isHybrid) {
                            HybridBridge.test.onClickMapSelect();
                          } else {
                            changeTab(ESearchTabs.MAP, {pushHistory: true});
                          }
                        }}
                      >
                        지도 아이콘 클릭 발생
                      </button>
                    </div>
                  ),
                }}
              />

              <BuildInfoListItem
                data={{
                  type: EDataType.CUSTOM,
                  name: 'new home mode',
                  value: (
                    <div className={classNames(s.btn_wrap, s.flex_row)}>
                      {[9, 10, -1].map((i) => {
                        return (
                          <button
                            key={i}
                            onClick={() => {
                              i > 0
                                ? LocalStorage.setItem(NEW_HOME_KEY, i > 9)
                                : LocalStorage.removeItem(NEW_HOME_KEY);
                              window.location.reload();
                            }}
                          >
                            {i > 0 ? `${i}.x 모드` : '지우기'}
                          </button>
                        );
                      })}
                    </div>
                  ),
                }}
              />

              <BuildInfoListItem
                data={{
                  type: EDataType.CUSTOM,
                  name: 'BuildInfo 높이 수정',
                  value: (
                    <div className={classNames(s.btn_wrap, s.flex_row)}>
                      <input
                        value={buildInfoBottom}
                        onChange={(e) => {
                          const value = Number(e.target.value);

                          if (isNaN(value)) {
                            return;
                          }

                          LocalStorage.setItem(BUILD_INFO_BOTTOM_STORAGE_KEY, value);
                          setBuildInfoBottom(value);
                        }}
                      />
                    </div>
                  ),
                }}
              />

              {Object.keys(reduxState).map((n) => (
                <BuildInfoListItem
                  data={{
                    name: n,
                    value: reduxState[n],
                    type: EDataType.OBJECT,
                  }}
                  key={n}
                />
              ))}
              {isLocalOrDev && (
                <li className={s.list_item}>
                  <h3>Performance Log</h3>

                  <div className={classNames(s.data_wrap, s.performance_data)}>
                    <span>
                      페이지 컴포넌트 시작 시간
                      <br />
                      <span className={s.timestamp}>{viewing.startTime}</span>
                    </span>
                  </div>

                  <div className={classNames(s.data_wrap, s.performance_data)}>
                    <span>{''}</span>
                    <span>A</span>
                    <span>B</span>
                  </div>

                  {viewing.items.map((item) => (
                    <div className={classNames(s.data_wrap, s.performance_data)} key={item.title}>
                      <span>{item.title} </span>
                      <span>{item.time - viewing.startTime} </span>
                      {item.duration && <span>{item.duration}</span>}
                    </div>
                  ))}

                  <div className={classNames(s.data_wrap, s.desc)}>
                    <p> A : 페이지 컴포넌트 시작 시간으로 부터의 경과시간 (ms 단위) </p>
                    <p> B : 동일한 액션의 시작시간으로 부터의 경과시간 (ms 단위) </p>
                  </div>
                </li>
              )}
              <BuildInfoListItem
                data={{
                  type: EDataType.CUSTOM,
                  name: 'openNearBy interface',
                  value: (
                    <div className={classNames(s.btn_wrap, s.inline_button)}>
                      {Object.keys(DefaultDeepLinkReqKeyMap).map((i) => {
                        return (
                          <button
                            key={i}
                            onClick={() => {
                              sendWithScheme('openNearBy', [{key: 'reqKey', value: i}]);
                            }}
                          >
                            {i}
                          </button>
                        );
                      })}
                    </div>
                  ),
                }}
              />
            </TabContent>

            <TabContent tabKey={EBuildInfoTabs.LINK}>
              {/* {devInfos.map((n, i) => (
                <BuildInfoListItem data={n} key={i} />
              ))} */}

              <BuildInfoListItem
                data={{
                  type: EDataType.CUSTOM,
                  name: 'origin input (with localstorage)',
                  value: <BuildInfoOriginInput />,
                }}
              />
              {testInfos.map((n, i) => (
                <BuildInfoListItem data={n} key={i} />
              ))}
            </TabContent>

            <li className={s.end_item}>리스트의 마지막입니다</li>
          </div>
        </Tabs>
      </div>
      <button
        className={s.btn_env}
        style={
          {
            '--bottom': `${buildInfoBottom}px`,
          } as CSSProperties
        }
        onClick={(e) => {
          e.stopPropagation();
          setView((prevState) => !prevState);
        }}
      >
        {`${APP_SERVER_NAME}-${rdLayout.appSize.isLandscape ? 'L' : 'P'}`}
        <br />
        {packageInfo.version}
        {TmapInfoFromApp && (
          <>
            <br />
            {TmapInfoFromApp.packageVersion}
            <br />
            {TmapInfoFromApp.deviceName}({TmapInfoFromApp?.osVersion})
          </>
        )}
      </button>
      {showWebviewCover && <div className={s.webview_cover} />}
    </div>
  );
};

export default BuildInfo;
